import { Segmented } from "antd";
import classNames from "classnames";
import React, { useState } from "react";
import { Container, Input } from "reactstrap";
import {
  LogoDavidoff,
  LogoKendrabeauty,
  LogoLevis,
  LogoNordstrom,
  LogoSheraton,
  LogoTheessentials,
} from "../../img/logo";
import { boot } from "../../utils";
import Header from "../HeroLayouts/HeroLayoutsType1th/Header/Header";
import { IconAdd, IconMinus } from "./assets";
import styles from "./index.module.css";
import usePricing from "./usePricing";

const Feature = (props) => {
  const { disabled, children } = props;

  return (
    <div
      className={classNames(styles["feature"], {
        [styles["feature--disabled"]]: disabled,
      })}
    >
      <div>•</div>
      <div style={{ textDecoration: disabled ? "line-through" : "none" }}>
        {children}
      </div>
    </div>
  );
};

const RoundedButton = (props) => {
  const { children, onClick, style = undefined } = props;

  return (
    <div className={styles["rounded-button"]} style={style} onClick={onClick}>
      {children}
    </div>
  );
};

const Question = (props) => {
  const { title, children } = props;

  const [folded, setFolded] = useState(true);

  return (
    <div
      className={classNames(styles["question"], {
        [styles["question--active"]]: folded === false,
      })}
    >
      <div
        className={styles["question__title"]}
        onClick={() => {
          setFolded(!folded);
        }}
      >
        {title}
        <div className={styles["question__toggle"]}>
          {folded ? <IconAdd /> : <IconMinus />}
        </div>
      </div>
      {folded === false && (
        <div className={styles["question__contents"]}>{children}</div>
      )}
    </div>
  );
};

const PricingCard = (props) => {
  const { title, price, description, children } = props;

  return (
    <div className={styles["pricing-card"]}>
      <div className={styles["pricing-card__header"]}>
        <div className={styles["pricing-card__title"]}>{title}</div>
        <div style={{ marginTop: 12, marginBottom: 12 }}>Starting at</div>
        <div className={styles["pricing-card__price"]}>
          <div className={styles["pricing-card__price-value"]}>
            ${price.toLocaleString()}
          </div>
          <div className={styles["pricing-card__price-unit"]}>/month</div>
        </div>
        <div style={{ marginTop: 12, marginBottom: 24, height: 57 }}>
          {description}
        </div>
        <div>
          <RoundedButton
            style={{
              background: "#E72955",
            }}
            onClick={() => {
              window.open(
                "https://desktop.engagemessage.com/login/sign-up" +
                  `?prefer=${encodeURIComponent(title)}`,
                "_blank"
              );
            }}
          >
            Get started
          </RoundedButton>
        </div>
      </div>
      <div className={styles["pricing-card__body"]}>{children}</div>
    </div>
  );
};

// 将字符串类型的数字转换类型，处理 NaN 的情况，并进行比较
const reachLimit = (val, limit) => {
  const _val = parseInt(val, 10);
  const value = isNaN(_val) ? 0 : _val;

  return value > limit;
};

const Pricing = () => {
  const { state, setState, comboPlan, emailPlan, smsPlan } = usePricing();

  // emailCount > 20000 || smsCount > 10000 时，显示 Chat with us 按钮
  const isReachLimit =
    reachLimit(state.emailContactCount, 20000) ||
    reachLimit(state.smsContactCount, 10000);

  return (
    <div className={styles["page"]} style={{ paddingTop: 30 }}>
      <Container>
        <Header></Header>
        <div className={styles["page-title"]}>
          Take your marketing to the next level
        </div>
        <div>
          Join the customer platform proven to drive repeat sales, increase
          retention, and provide higher lifetime value.
        </div>
        <div className={styles["page-price-tips"]}>Estimate your price</div>
        <div className={styles["input-group"]}>
          <div>How many email contacts do you have?</div>
          <Input
            bsSize="lg"
            type="number"
            value={state.emailContactCount}
            onChange={(e) => {
              // emailContactCount 是字符串类型，在 hooks 中完成类型的转换
              setState({ ...state, emailContactCount: e.target.value });
            }}
          />
        </div>
        <div className={styles["input-group"]}>
          <div>How many SMS contacts do you have?</div>
          <Input
            bsSize="lg"
            type="number"
            value={state.smsContactCount}
            onChange={(e) => {
              setState({ ...state, smsContactCount: e.target.value });
            }}
          />
        </div>
        {comboPlan && emailPlan && smsPlan && (
          <div className={styles["cards-wrapper"]}>
            {isReachLimit ? (
              <>
                <div
                  style={{
                    fontSize: "32px",
                    fontWeight: 400,
                    color: "#000000",
                    lineHeight: "38px",
                  }}
                >
                  Let's talk!
                </div>
                <div style={{ margin: "16px 0 24px" }}>
                  Please reach out for a custom quote.
                </div>
                <RoundedButton
                  onClick={() => {
                    boot();
                  }}
                >
                  Chat With Us
                </RoundedButton>
              </>
            ) : (
              <>
                <div className={styles["segmented-control"]}>
                  <Segmented
                    options={["Yearly", "Monthly"]}
                    value={state.interval}
                    onChange={(interval) => setState({ ...state, interval })}
                  />
                </div>
                <div className={styles["pricing-cards"]}>
                  <PricingCard
                    title="Email & SMS"
                    price={comboPlan.price_per_month}
                    description="Make your marketing go even further by reaching customers through both email and SMS."
                  >
                    <Feature>
                      Manage up to {comboPlan.contacts_capacity} Email contacts
                    </Feature>
                    <Feature>
                      Manage up to {comboPlan.sms_contacts_capacity} SMS
                      contacts
                    </Feature>
                    <Feature>Unlimited email sends</Feature>
                    <Feature>
                      {comboPlan.sms_credit_capacity} SMS credits per month
                    </Feature>
                    <Feature>Free phone number</Feature>
                    <Feature>Email automation Series</Feature>
                    <Feature>Email campaign broadcast</Feature>
                    <Feature>SMS automation Series</Feature>
                    <Feature>SMS campaign broadcast</Feature>
                    <Feature>Instagram & Facebook automation Series</Feature>
                    <Feature>Data tracking, analytics and reports</Feature>
                    <Feature>Chat Inbox for conversational support</Feature>
                    <Feature>Smart customer segments</Feature>
                    <Feature>Remove EngageMessage branding</Feature>
                    <Feature>24/7 customer support</Feature>
                  </PricingCard>
                  <PricingCard
                    title="Email"
                    price={emailPlan.price_per_month}
                    description="Take the next step in your email marketing with a simple solution that personalizes at scale."
                  >
                    <Feature>
                      Manage up to {emailPlan.contacts_capacity} Email contacts
                    </Feature>
                    <Feature disabled>
                      Manage up to {comboPlan.sms_contacts_capacity} SMS
                      contacts
                    </Feature>
                    <Feature>Unlimited email sends</Feature>
                    <Feature disabled>
                      {comboPlan.sms_credit_capacity} SMS credits per month
                    </Feature>
                    <Feature disabled>Free phone number</Feature>
                    <Feature>Email automation Series</Feature>
                    <Feature>Email campaign broadcast</Feature>
                    <Feature disabled>SMS automation Series</Feature>
                    <Feature disabled>SMS campaign broadcast</Feature>
                    <Feature>Instagram & Facebook automation Series</Feature>
                    <Feature>Data tracking, analytics and reports</Feature>
                    <Feature>Chat Inbox for conversational support</Feature>
                    <Feature>Smart customer segments</Feature>
                    <Feature>Remove EngageMessage branding</Feature>
                    <Feature>24/7 customer support</Feature>
                  </PricingCard>
                  <PricingCard
                    title="SMS"
                    price={smsPlan.price_per_month}
                    description="Take the next step in your SMS marketing with a simple solution that personalizes at scale."
                  >
                    <Feature disabled>
                      Manage up to {comboPlan.contacts_capacity} Email contacts
                    </Feature>
                    <Feature>
                      Manage up to {smsPlan.contacts_capacity} SMS contacts
                    </Feature>
                    <Feature disabled>Unlimited email sends</Feature>
                    <Feature>
                      {smsPlan.sms_credit_capacity} SMS credits per month
                    </Feature>
                    <Feature>Free phone number</Feature>
                    <Feature disabled>Email automation Series</Feature>
                    <Feature disabled>Email campaign broadcast</Feature>
                    <Feature>SMS automation Series</Feature>
                    <Feature>SMS campaign broadcast</Feature>
                    <Feature>Instagram & Facebook automation Series</Feature>
                    <Feature>Data tracking, analytics and reports</Feature>
                    <Feature>Chat Inbox for conversational support</Feature>
                    <Feature>Smart customer segments</Feature>
                    <Feature>Remove EngageMessage branding</Feature>
                    <Feature>24/7 customer support</Feature>
                  </PricingCard>
                </div>
              </>
            )}
          </div>
        )}
        <div className={styles["faq"]}>Frequently Asked Questions</div>
        <Question title="What happens if I exceed my contact limit?">
          You will be automatically upgraded to the next pricing tier.
        </Question>
        <Question title="What happens if I go under my contact limit?">
          No worries, we will protect you from over-charging. You will be
          automatically moved to a lower pricing tier in the next billing cycle.
        </Question>
        <Question title="What is SMS credit?">
          For US and Canada numbers, each SMS(plain text messages less than 160
          characters) cost 1 credit, while each MMS(image/gif/emojis) costs 5
          credits. For UK and Australia numbers, each SMS costs 10 credits. For
          rest of the world, each SMS costs 10 credits.
        </Question>
        <Question title="Can I send MMS?">
          Yes. You can send MMS to US and Canada numbers. Each MMS message costs
          5 SMS credits.
        </Question>
        <Question title="What happens if I exceed my SMS monthly credit limit?">
          You will be automatically charged for the extra SMS credits for that
          month. <br />1 SMS credit = $0.02
        </Question>
        <div className={styles["brands"]}>
          <div className={styles["brands__title"]}>Trusted by top brands</div>
          <div className={styles["brands__images"]}>
            <img src={LogoNordstrom} alt="" />
            <img src={LogoDavidoff} alt="" />
            <img src={LogoLevis} alt="" />
            {/* 在移动端不显示下面的图像 */}
            <img
              className={styles["brands--hidden"]}
              src={LogoTheessentials}
              alt=""
            />
            <img src={LogoKendrabeauty} alt="" />
            <img src={LogoSheraton} alt="" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Pricing;
